// Don't move this into the Routes object.
// Keep them separate!

export interface MenuPage {
  title: string;
  url: string;
  queryParams?: { [key: string]: string };
  icon?: string;
  showFor: ViewRoles[];
  children?: MenuPage[];
}

export const MainPages: MenuPage[] = [
  {
    title: 'Register',
    url: '/registration',
    icon: 'person',
    showFor: [ViewRoles.NotLoggedIn],
  },
  {
    title: 'Login',
    url: '/login',
    icon: 'person',
    showFor: [ViewRoles.NotLoggedIn],
  },
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: 'albums',
    showFor: [ViewRoles.Broker, ViewRoles.Lender, ViewRoles.Admin],
  },
  {
    title: 'Applications',
    url: '/apps',
    // queryParams: { tab: 'applications' },
    icon: '',
    showFor: [ViewRoles.Broker, ViewRoles.Lender, ViewRoles.Admin],
    children: [
      {
        title: 'Lead Search',
        url: '/leads',
        // queryParams: { tab: 'leads' },
        icon: 'search',
        showFor: [ViewRoles.Broker, ViewRoles.Admin],
      },
      {
        title: 'Applications Search',
        url: '/apps',
        // queryParams: { tab: 'applications' },
        icon: 'search',
        showFor: [ViewRoles.Broker, ViewRoles.Lender, ViewRoles.Admin],
      },
      {
        title: 'Funded Search',
        url: '/funded',
        // queryParams: { tab: 'applications' },
        icon: 'search',
        showFor: [ViewRoles.Broker, ViewRoles.Lender, ViewRoles.Admin],
      },
      {
        title: '+New Lead',
        url: '/new-lead',
        icon: 'search',
        showFor: [ViewRoles.Broker, ViewRoles.Admin],
      },
      {
        title: '+New Application',
        url: '/new-application',
        icon: 'search',
        showFor: [ViewRoles.Broker, ViewRoles.Lender, ViewRoles.Admin],
      },
    ],
  },
  {
    title: 'Messages',
    url: '/messages',
    icon: '',
    showFor: [ViewRoles.NotLoggedIn],
  },
  {
    title: 'Admin',
    url: '/admin/affiliates',
    icon: 'cog',
    showFor: [ViewRoles.Admin],
    children: [
      {
        title: 'Affiliate Search',
        url: '/admin/affiliates',
        icon: 'search',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'Broker Search',
        url: '/admin/brokers',
        icon: 'search',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'Contact Search',
        url: '/admin/contacts',
        icon: 'search',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'User Search',
        url: '/admin/users',
        icon: 'search',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'Lender Search',
        url: '/admin/lenders',
        icon: 'search',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'Reports',
        url: '/admin/reports',
        icon: 'reports',
        showFor: [ViewRoles.Admin],
      },
      {
        title: 'Settings',
        url: '/admin/settings',
        icon: 'cog',
        showFor: [ViewRoles.Admin],
      },
    ],
  },
  {
    title: 'Account',
    url: '/account/profile/',
    icon: 'person-circle',
    showFor: [ViewRoles.Any],
    children: [
      {
        title: 'Account Profile',
        url: '/account/profile/',
        icon: 'person-circle',
        showFor: [ViewRoles.Any],
      },
      {
        title: 'Login Information',
        url: '/account-login-info/',
        icon: 'lock-closed',
        showFor: [ViewRoles.Any],
      },
      {
        title: 'Stored Emails',
        url: '/account-stored-emails/',
        icon: 'mail',
        showFor: [ViewRoles.Any],
      },
    ],
  },
  {
    title: 'Log out',
    url: '/logout',
    icon: 'log-out',
    showFor: [ViewRoles.Any],
  },
];

export const FooterPages = [
  {
    title: 'Admin',
    url: '/admin-search',
    icon: 'cog',
    showFor: 2,
    children: [],
  },
  {
    title: 'Account',
    url: '/account-info/',
    icon: 'person-circle',
    showFor: 1,
    children: [
      {
        title: 'Account Profile',
        url: '/account-info/',
        icon: 'person-circle',
        showFor: 1,
      },
      {
        title: 'Login Information',
        url: '/account-login-info/',
        icon: 'lock-closed',
        showFor: 1,
      },
      {
        title: 'Stored Emails',
        url: '/account-stored-emails/',
        icon: 'mail',
        showFor: 1,
      },
    ],
  },
  {
    title: 'Log out',
    url: '/logout',
    icon: 'log-out',
    showFor: 1,
    children: [],
  },
];

export const enum ViewRoles {
  NotLoggedIn = 'NotLoggedIn',
  Any = 'Any',
  // Applicant = 'Applicant',
  Affiliate = 'Affiliate',
  Broker = 'Broker',
  Lender = 'Lender',
  Admin = 'Admin',
}
