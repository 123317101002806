<!------------------------------ Begin Mobile Menu ------------------------------>
<div id="MobileMenuContainer" class="MobileMenuContainer">
  <ul class="MobileMenu">
    <li>
      <h1>Main Menu</h1>
    </li>
    @for (menu of menuService.mainMenu(); track menu; let i = $index) {
      <li (click)="activePage = i" routerDirection="root" lines="none" detail="false" [class.Active]="isActiveRoute(menu.url)">
        @if (menu.children === null || menu.children === undefined) {
          <a onClick="HideMenuDelayed()" href="{{ menu.url }}">{{ menu.title }}</a>
        }
        @if (menu.children !== null && menu.children !== undefined) {
          <a id="{{ menu.title }}_Menu" class="DrawerArrow HasDrawer" onClick="ExpandArrow(this); ExpandDrawer(this);" href="{{ menu.url }}">{{ menu.title }}</a>
        }
        @if (menu.children !== null && menu.children !== undefined) {
          <div id="{{ menu.title }}_Menu_Submenu" class="MobileMenuDrawerContainer">
            <ul class="MobileMenuDrawer">
              @for (child of menu.children; track child) {
                <li>
                  <a onClick="HideMenuDelayed()" href="{{ child.url }}">{{ child.title }}</a>
                </li>
              }
            </ul>
          </div>
        }
      </li>
    }
  </ul>
</div>
<!------------------------------ End Mobile Menu ------------------------------>
