<!------------------------------ Begin Mobile Menu Icon ------------------------------>
<div class="MobileMenuIconContainer">
  <div class="MobileMenuIcon" id="ShowMenuIcon" onClick="ShowMenu()">
    <img src="/assets/images/global/icon_menu.svg" alt="Menu Icon" />
  </div>
  <div class="MobileMenuIcon" id="HideMenuIcon" onClick="HideMenu()" style="display: none">
    <img src="/assets/images/global/icon_menu.svg" alt="Menu Icon" />
  </div>
</div>
<!------------------------------ End Mobile Menu Icon ------------------------------>

<div class="LogoContainer HoverEffect">
  <a href="/applications-search"><img src="/assets/images/global/logo_netloan-funding.svg" alt="Logo netLoan Funding" /></a>
</div>

<!------------------------------ Begin Main Menu ------------------------------>
<div class="MainMenuContainer">
  <ul class="MainMenu">
    @for (menu of menuService.mainMenu(); track menu) {
      <li routerDirection="root" lines="none" detail="false" [class.HasDropdown]="menu.children !== null && menu.children !== undefined">
        <a [routerLink]="menu.url" [queryParams]="menu.queryParams || {}" routerLinkActive="Active">
          {{ menu.title }}
          @if (menu.children !== null && menu.children !== undefined) {
            <span></span>
          }
        </a>
        @if (menu.children !== null && menu.children !== undefined) {
          <div class="DropdownMenuContainer" id="ApplicationsMenu">
            <ul class="DropdownMenu">
              @for (child of menu.children; track child) {
                <li>
                  <a [routerLink]="child.url" [queryParams]="child.queryParams || {}">{{ child.title }}</a>
                </li>
              }
            </ul>
          </div>
        }
      </li>
    }
  </ul>
  <div class="Clear"></div>
</div>
<!------------------------------ End Main Menu ------------------------------>
