import { computed, inject, Injectable } from '@angular/core';

import { MainPages, ViewRoles } from '../app.menu';
import { AuthService } from '../core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  authService = inject(AuthService);

  mainMenu = computed(() => {
    const isLoggedIn = this.authService.user() !== null;

    return MainPages.filter(item => {
      // Admins can see everything (but ignore the views for users not logged in).
      if (this.authService.userIsAdmin && !item.showFor.includes(ViewRoles.NotLoggedIn)) {
        return true;
      }

      // Users that are NOT logged in can only see items that are marked as 'NotLoggedIn'.
      if (!isLoggedIn) {
        return item.showFor.includes(ViewRoles.NotLoggedIn);
      }

      // Logged in users can see items that are marked as Any or their role.
      return item.showFor.some(viewRole => viewRole === ViewRoles.Any || this.authService.userViewRoles.includes(viewRole));
    });
  });
}
